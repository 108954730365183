@import './variables';
@import './functions';

.tooltip-container {
    position: absolute;
    background-color: black;
    z-index: 10;
    color: white;
    top: 40px;
    padding: 10px;
    border-radius: 10px;
};

.tooltip-mobile-container {
    max-width: 150px;
    width: 100%;
    position: fixed;
    top: 0;
    right: 15px;
    background: $primary;
    color: white;
    font-size: rem(13);
    padding: 15px 15px 15px 34px;
    border-radius: 10px;
    animation: upAndDown 1 2s; // animation name - iteration - timing
    opacity: 0;
    z-index: 991;

    @media screen and (min-width: $screen-lg) {
        position: absolute;
        top: 0;
        left: calc(100% + 10px);
        padding: 10px;
        animation: none;
        opacity: 1;
        z-index: 10;
    }

    .tooltip-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);

        svg {
            object-fit: contain;
        }
    }
}

@keyframes upAndDown {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    25% {
        transform: translateY(70px);
        opacity: 1;
    }

    75% {
        transform: translateY(70px);
        opacity: 1;
    }

    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}
