@import "./variables";
@import './functions';

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    text-align: left;

    @media screen and (min-width: $screen-lg) {
        padding-left: 35px;
        height: 20px;
        font-size: 22px;
    }

    .my-checkbox {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }

    .checkmark:after {
        content: '';
        display: none;
    }

    .my-checkbox:checked + .checkmark:after {
        display: block;
        position: absolute,
    }

    .checkmark {
        position: absolute;
        top: 4px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $white;
        border-radius: 3px;
        border: 1px solid $clear-grey;

        @media screen and (min-width: $screen-lg) {
            top: 0;
        }

        &:after {
            top: 1px;
            left: 2px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: url('../icons/checkbox/check_checkbox.svg') no-repeat center center;
            background-size: contain;
        }
    }

    .label-text {
        font-size: rem(12);
        font-family: $font-light;

        @media screen and (min-width: $screen-lg) {
            position: absolute;
            top: 0;
            font-family: $font-primary;
        }
    }
}
