@import "../../static/scss/variables";

.payment-header {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;
    background-color: white;
    padding-left: 0;
    z-index: 3;

    @media screen and (min-width: $screen-lg) {
        height: 110px;
        padding-left: 35px;
    }

    .wis-logo-container,
    .right {
        flex: 1;
    }

    .right {
        display: none;

        @media screen and (min-width: $screen-lg) {
            display: block;
        }
    }

    .modal-cancel {
        position: absolute;
        left: 35px;
        bottom: -113px;
        padding: 1rem 2rem;
        border: 1px solid $border-grey;
        background-color: $white;
        border-radius: 10px;
        min-width: 150px;

        span {
            font-size: rem(13);
            font-family: $font-medium;
        }
    }

    .wis-logo-container {
        background: none;

        a {
            display: inline-block;
        }

        &:hover {
            #triangle{
                fill: $primary;
            }
        }
    }

    .my-breadcrumb {
        .my-breadcrumb-item {
            &:not(:last-child) {
                margin-right: 1rem;

                &::after {
                    content: "";
                    background: transparent url("../../static/icons/plans/header/breadcrumb/arrow_breadcrumb.svg") no-repeat
                        center;
                    transform: rotate(180deg);
                    height: 15px;
                    width: 15px;
                    margin-left: 15px;
                }
            }

            .badge {
                margin-right: 15px;
                font-family: $font-light;
                border-radius: 50%;
                background-color: white;
                font-size: rem(12);
                height: 20px;
                width: 20px;

                &-inactive {
                    border: 1px solid $blue-grey;
                    color: $blue-grey;
                }

                &-active {
                    background-color: $primary;
                }

                &-complete {
                    background-color: $blue-grey;
                }
            }

            .text {
                font-family: $font-light;
                font-size: rem(12);
                color: $blue-grey;

                &-active {
                    font-family: $font-semi-bold;
                    color: $primary;
                }
            }
        }
    }
}
