@import '../../../static/scss/variables';
@import '../../../static/scss/functions';

/*
    MOBILE STICKY PRICING
*/

.mobile-sticky-pricing {
    position: -webkit-sticky;
    position: sticky;
    top: 69px; // prevent 1px gap
    z-index: 2;
    background: $grey-white;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: 15px 20px;

    @media screen and (min-width: $screen-lg) {
        display: none;
    }
}
