@import "./variables";
@import './functions';

.middle-container {
    position: relative;

    &.my-account-view {
        padding: 20px;
        box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.05);
        border-radius: 30px;
    
        @media screen and (min-width: $screen-lg) {
            padding: 2%;
            border-radius: 10px;
            box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.05);
        }
    
        .creation-container {
            background-color: $light-blue-grey;
            border-radius: 25px;
            padding: 2px 15px;
        }
        
        .plan-container {
            background-color: $light-blue-grey;
            border-radius: 3px;
            padding: 10px 15px;
            color: $clear-blue-grey !important;
        }
    
        .account-value {
            color: $clear-blue-grey;
        }
    }

    &.service-card {

        @media screen and (max-width: $screen-lg) {
            padding: 20px;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
            border-radius: 30px;

            h2 {
                font-size: rem(16);
            }
        }
    }
}

.edit-account-view {
    width: 100%;

    @media screen and (min-width: $screen-lg) {
        width: 400px;
    }
}

.account-title {

    @media screen and (max-width: $screen-lg) {
        font-size: rem(16);
    }
}

.expired-content {

    @media screen and (max-width: $screen-lg) {
        position: absolute;
        top: 66px;
        left: 50%;
        padding: 4px 10px 7px;
        border-radius: 30px;
        transform: translateX(-50%);
        white-space: nowrap;
        background: rgba($error, 0.05);
    } 
}
