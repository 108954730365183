@font-face {
    font-family: 'Poppins light';
    src: url('../font/Poppins/Poppins-Light.ttf');
}

@font-face {
    font-family: 'Poppins regular';
    src: url('../font/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'Poppins medium';
    src: url('../font/Poppins/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'Poppins semi-bold';
    src: url('../font/Poppins/Poppins-SemiBold.ttf');
}