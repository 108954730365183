/**** Fonts ***/
$font-primary: 'Poppins regular', sans-serif;
$font-light: 'Poppins light', sans-serif;
$font-semi-bold: 'Poppins semi-bold', sans-serif;
$font-medium: 'Poppins medium', sans-serif;

/***+ Colors ***/
//to use
$blue-grey: #ced3de !default;
$grey-white: #f8f9fa !default;
$very-clear-grey: #f0f2f4 !default;
$border-grey: #dde1e4 !default;
$clear-blue-grey: #6b7897 !default;
$light-blue-grey: #f8f9fa !default;
$light-grey: #efefef !default;
$clear-grey: #e1e3ea !default;
$clear-text: #bfc7ce !default;
$main-texts: #333 !default;
$primary: #009BDE !default;
$white: #fff !default;
$secondary: #006C9A !default;
$active: #00425F !default;
$green: #aeeacf !default;
$processing: #fcb922 !default;
$success: #7ed321 !default;
$error: #d0021b !default;

//to delete
$black: #000 !default;
$blue: $primary !important;
$blue-petroleum: #285165 !important;
$pink: #f50a6b !important;
$semi-light-grey: #777777 !default;
$grey: #939faf !default;

/****** Gradient *****/
// Use https://mycolor.space/gradient to generate clean gradients
$gradient-main: linear-gradient(
                to right bottom,
                #009ce1, #5690ed, #937ee8, #c863d2, #ef3fa9
);
$gradient-main-inverted: linear-gradient(180deg, rgba(249, 90, 91, 0) 0%, #f95a5b 100%), #f50a6b;

/***** Sidebar *******/
$sidebar-width: 220px;

/***** Header ********/
$header-height: 110px;

/***** Table  ********/
$row-selected: rgba(#2196f3, 0.05);

// Breakpoints
$screen-lg: 992px;
$screen-md: 768px;
$screen-sm: 641px;
