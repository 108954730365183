@import "../../../static/scss/variables";
@import "../../../static/scss/functions";
@import "../../../static/scss/_pagination";

.table-plan {
  width: 100%;

  @media screen and (min-width: $screen-lg) {
    width: calc(100% - 12.5%);
    margin-right: 12.5%;
  }

  thead > tr > th {
    font-family: $font-semi-bold !important;
    font-size: rem(15);
    height: 45px;
    color: $main-texts;
    padding: 0;
  }
  tbody > tr {
    border-bottom: 1px solid #eff1f3;

    th {
      font-size: rem(13);
      color: $main-texts;
      width: 50%;

      @media screen and (min-width: $screen-lg) {
        width: auto;
      }
    }

    td {
      color: $primary;
      display: none;
      width: 50%;

      @media screen and (min-width: $screen-lg) {
        display: table-cell;
        width: auto;
      }

      &.active {
        display: table-cell;
        border-right: 0;

        @media screen and (min-width: $screen-lg) {
          display: table-cell;
          width: auto;
        }
      }
    }

    td,
    th {
      padding-right: rem(14);
      font-size: rem(13);
      height: 45px;
      border-left: 1px solid #eff1f3;
      border-right: 1px solid #eff1f3;
      transition: transform 500ms ease-in-out;

      &:first-child,
      &:last-child {
        border-left: 0;
        border-right: 0;
      }

      svg {
        fill: $success;
        width: 21px;
        height: 19px;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &.payment {
    tbody > tr {
      td,
      th {
        border: 1px solid $very-clear-grey;
      }
    }
  }
}

// Specific to modal `<table>`
.modal-comparative .table-plan {
  .td-mobile-title {
    display: block;
    color: $clear-blue-grey;
    font-size: rem(13);
    font-family: $font-semi-bold;

    span {
      font-size: rem(7);
      font-family: $font-primary;
    }

    @media screen and (min-width: $screen-lg) {
      display: none;
    }
  }

  @media screen and (max-width: $screen-lg) {
    thead th {
      display: none;
      width: 100% !important;

      &:first-child {
        display: block;
        padding: 0;
        font-size: rem(20);
        font-family: $font-semi-bold;
        color: $clear-blue-grey;
        text-align: left !important;
        margin-bottom: 15px;

        span {
          font-size: rem(11);
        }
      }
    }

    tbody th:first-child {
      padding: 0;
    }

    thead th,
    tbody td {
      padding: 0;
      border: 0;
    }

    tbody th,
    tbody td {
      display: flex;
      width: 100% !important;
      justify-content: center;
    }

    tbody td {
      justify-content: space-between;
    }

    tbody tr {
      border: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      background: $grey-white;
      height: 140px;
      padding: 20px;
      border-radius: 30px;

      + tr {
        margin-top: 15px;
      }
    }
  }
}
