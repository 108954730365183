@import './variables';
@import './functions';

$breakpoint-mobile: 991px;

#image-login {
    background: no-repeat center / cover url(../images/login-background.jpeg);
}

.auth-container {
    width: 360px;
    min-height: 485px;

    svg {
        max-width: 200px;
    }

    #login-laius {
        font-family: $font-primary;
        color: $clear-blue-grey;
        margin: 0 0 30px;
    }

    form {
        max-height: auto;

        @media screen and (min-width: $screen-lg) {
            max-height: 260px;
        }

        #forgotten-password {
            color: $clear-blue-grey;
            text-decoration: underline;
        }
    }

    #solution-wis {
        padding: 20px;
        background: $light-blue-grey;
        display: flex;
        gap: 20px;
        align-items: center;
        border-radius: 10px;
        margin-top: 40px;
    }
}

.create-account {
    color: $blue-grey;
    text-decoration: underline;

    &:hover {
        color: $clear-blue-grey !important;
    }
}

// Error message
.credential-error {
    border-radius: 25px;
    padding: 7px 10px 7px 55px;
    font-size: rem(13);
    background: url('../icons/info-error.svg') no-repeat 17px 50%, rgba(208, 2, 27, 0.05);
}

#validation-container {
    .invalid-text {
        color: $processing;
    }
    p {
        font-size: rem(12);
    }
}

@media (max-width: $breakpoint-mobile) {
    .auth-container {
        width: 250px;
    }
}
