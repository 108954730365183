@import "../../static/scss/variables";
@import "../../static/scss/functions";

$left-card-width: 30%;
$card-width: 22%;

.plans-component-container {
  position: relative;

  @media screen and (min-width: $screen-lg) {
    margin: 0 5rem;
  }

  .sticky-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: white;

    @media screen and (min-width: $screen-lg) {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: stretch;
      max-width: 75%;
    }

    .sticky-header__card {
      padding: 25px 30px;
      color: $primary;
      width: 100%;
      min-height: 165px;
      border-radius: 10px;
      background-color: #F6F8FC;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: 15px;

      @media screen and (min-width: $screen-lg) {
        padding: 0.9375rem 0;
        max-width: 30%;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        transition: transform 500ms ease-in-out, padding 500ms ease-in-out;
      }

      &.reduced {
        padding: 0.5rem 0;

        .sticky-header__card__pricing-per-month,
        .sticky-header__card__consumption-laius {
          padding: 0.1rem 0;
          transform: scale(1);

          @media screen and (min-width: $screen-lg) {
            transform: scale(0.8);
          }
        }
      }

      .sticky-header__card__title {
        color: black;
        font-size: rem(28);
        text-transform: uppercase;
        flex-basis: 100%;
        text-align: center;
        margin-bottom: 20px;
        line-height: 1;

        @media screen and (min-width: $screen-lg) {
          font-size: rem(22);
          line-height: rem(26);
          flex-basis: auto;
          text-transform: initial;
          margin-bottom: 0.5rem;
        }
      }

      .sticky-header__card__consumption-laius {
        color: $primary;
        width: 100%;
        text-align: center;
        order: 4;
        margin: 15px 0 5px;

        @media screen and (min-width: $screen-lg) {
          cursor: pointer;
          padding-bottom: 1rem;
          order: inherit;
          width: auto;
          margin: 0;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .sticky-header__card__pricing-per-month {
        display: flex;
        align-items: baseline;
        justify-content: center;
        flex-basis: 100%;
        order: 3;
        transition: padding 500ms ease-in-out;

        @media screen and (min-width: $screen-lg) {
          align-items: initial;
          justify-content: inherit;
          flex-basis: inherit;
          padding: 0;
          order: inherit;
        }
      }

      .price {
        font-family: $font-semi-bold;
        font-size: rem(40);
        font-weight: 600;
        line-height: 1rem;
        color: $primary;

        @media screen and (min-width: $screen-lg) {
          font-size: rem(30);
        }
      }

      .euro {
        font-family: $font-semi-bold;
        color: $primary;
        line-height: 1.5rem;
        font-size: rem(16);
      }

      .text {
        font-size: rem(13);
        font-family: $font-light;

        @media screen and (min-width: $screen-lg) {
          font-size: rem(16);
          color: black;
          font-family: $font-primary;
        }
      }

      .trial-nb-consumption {
        font-size: rem(22);
        font-family: $font-semi-bold;
        font-weight: 600;
        line-height: 1.2rem;
        color: $primary;
      }

      .trial-text {
        font-size: rem(16);
        color: black;
      }

      .btn-white-main {
        border: none;
        order: 4;
        margin: 20px auto 0;

        @media screen and (min-width: $screen-lg) {
          order: inherit;
          margin-top: 0;
        }
      }

      &.disabled,
      &.disabled:hover {
        background: $blue-petroleum;
        color: white;
        box-shadow: none;
        align-items: center;
        align-content: center;

        @media screen and (min-width: $screen-lg) {
          opacity: 1;
          align-items: initial;
          align-content: initial;
        }
      }

      &.disabled.active {
        flex-direction: column;

        .sticky-header__card__title,
        .sticky-header__card__pricing-per-month,
        .sticky-header__card__current-plan,
        span {
          color: white;
        }

        .sticky-header__card__title {
          justify-content: center;
          font-size: rem(28);
          text-transform: uppercase;
          flex-basis: 100%;
          text-align: center;
          margin-bottom: 20px;

          @media screen and (min-width: $screen-lg) {
            transform: none;
            text-align: center;
            text-transform: none;
            font-size: rem(22);
            flex-basis: auto;
            margin-bottom: 0.5rem;
          }
        }

        .price {
          color: $primary;
        }

        .euro {
          color: $primary;
        }

        .sticky-header__card__pricing-per-month {
          justify-content: center;

          @media screen and (min-width: $screen-lg) {
            margin-left: inherit;
            margin-top: auto;
          }
        }

        .sticky-header__card__current-plan {
          height: 40px;
          min-width: 140px;
          padding: 0 12px;
          font-size: rem(13);
          line-height: rem(40);
          display: inline-block;
          width: max-content;
          margin: 17px auto;
          color: #f1f3f5;
          text-align: center;
          border-radius: 8px;
          background: #ced3de;
          order: 4;

          @media screen and (min-width: $screen-lg) {
            color: white;
            background: $primary;
            order: inherit;
          }
        }
      }
    }
  }

  h3 {
    font-family: $font-primary;
    color: $clear-blue-grey;
    font-size: rem(13);
    line-height: rem(20);

    @media screen and (min-width: $screen-lg) {
      color: $main-texts;
      font-size: rem(15);
      line-height: rem(18);
    }
  }

  .pricing-title--support {
    font-size: rem(18);
    line-height: rem(35);
    font-family: $font-primary;
    color: $black;
    margin: rem(25) 0;
  }

  .modal-comparative {
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(51, 51, 51, 0.3);
    z-index: 1024;

    .modal-comparative__dialog {
      min-height: 100%;
      max-width: 980px;
      display: flex;
      align-items: center;
      margin: 15px;

      @media screen and (min-width: $screen-lg) {
        margin: 15px auto;
        min-height: calc(100% - 30px);
      }

      .modal-comparative__dialog__content {
        padding: 2rem 2rem;
        background: #fff;
        width: 100%;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .modal-comparative__dialog__content__header {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        }

        .modal-comparative__dialog__content__close {
          width: fit-content;
          height: fit-content;
        }

        .modal-comparative__dialog__content__title {
          color: $primary;
          font-size: rem(20);
          line-height: rem(30);
          text-align: center;

          @media screen and (min-width: $screen-lg) {
            font-size: rem(24);
            line-height: rem(21);
            text-align: left;
          }
        }

        .modal-comparative__dialog__content__consumption-explanation {
          color: $black;
          font-size: rem(13);
          font-family: $font-primary;
          text-align: center;
          display: inline-block;

          @media screen and (min-width: $screen-lg) {
            font-size: rem(16);
            font-family: $font-light;
            text-align: left;
            display: inline;
          }
        }

        .modal-comparative__dialog__content__vat {
          color: #6f6f6f;
          align-self: flex-start;
          font-size: 10px;
        }
      }
    }
  }

  .pricing-title {
    color: $primary;
    font-size: rem(22);
    line-height: rem(26);
    margin-bottom: rem(20);

    @media screen and (min-width: $screen-lg) {
      font-size: rem(22);
      line-height: rem(35);
      margin-bottom: rem(25);
    }
  }

  .plans-card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (min-width: $screen-lg) {
      flex-direction: row;
    }
  }

  &.step-2 {
    .form-container {
      width: 100%;
      max-width: 600px;
      padding: 0 15px;
      order: 2;

      @media screen and (min-width: $screen-lg) {
        min-width: 600px;
        padding: 0;
        order: inherit;
      }
    }

    .plans-card-container {
      margin-right: 0;
      width: 100%;
      order: 1;

      @media screen and (min-width: $screen-lg) {
        width: 340px;
        order: inherit;
      }

      hr {
        width: 100%;
        margin-top: 0;
      }

      small {
        font-size: rem(12);
        font-family: $font-light;
      }

      .card-container {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        width: 100% !important;
        transform: scale(1.2);
        position: relative;
      }
    }

    .arrow-confirmation {
      transform: rotate(90deg);
      margin: 15px 0;
      order: 1;

      @media screen and (min-width: $screen-lg) {
        transform: rotate(0);
        margin: 0 1.5rem;
        order: inherit;
      }
    }
  }

  .plans-card-container {
    &.step-3 {
      max-height: 200px;
      order: 0;

      .btn-collapse-card {
        display: none;
      }

      .card-container-collapse[data-expanded="false"] {
        display: block;
      }

      @media screen and (min-width: $screen-lg) {
        order: inherit;
      }
    }

    .card-container {
      &.step-3 {
        height: 100% !important;
      }

      width: calc(33.33333% - 20px);
      height: auto;
      border-radius: 10px;
      background-color: white;
      transition: transform 0.5s ease-in-out;
      padding: 20px 20px;

      @media screen and (min-width: $screen-lg) {
        height: 377px;
      }

      .title {
        color: $main-texts;
        font-size: rem(28);
        line-height: 1;
        text-transform: uppercase;
        font-family: $font-semi-bold;
      }

      .price {
        position: relative;

        h2{
          color: $primary;
        }

        .euro {
          margin-top: 5px;
        }
        span {
          margin-top: 4px;
          color: $light-grey;
        }
      }

      .laius {
        p {
          position: relative;
          font-family: $font-semi-bold;
          color: $primary;
          font-size: rem(14);

          &::before {
            position: absolute;
            content: "";
            background: url("../../static/icons/plans/plus_blue.svg") no-repeat
              center center;
            background-size: contain !important;
            display: inline-block;
            margin-right: 5px;
            top: 2px;
            left: -25px;
            height: 15px;
            width: 15px;
          }
        }
      }

      .options-list {
        display: block;

        @media screen and (min-width: $screen-lg) {
          margin-left: 4px;
        }

        .price-card-item {
          font-size: rem(13);
          margin-bottom: 5px;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            background: url("../../static/icons/plans/check_blue.svg") no-repeat
              center center;
            background-size: contain !important;
            display: inline-block;
            margin-right: 5px;
            top: 2px;
            left: -30px;
            height: 15px;
            width: 15px;
          }
        }
      }

      // hover && active
      &:not(.disabled):hover {
        background-image: $gradient-main;
        transform: scale(1);

        @media screen and (min-width: $screen-lg) {
          transform: scale(1.08);
        }

        .options-list {
          .price-card-item {
            &::before {
              background: url("../../static/icons/plans/check_white.svg")
                no-repeat center center;
              height: 15px;
              width: 15px;
            }
          }
          li {
            color: $white;
          }
        }

        .laius {
          p {
            &::before {
              background: url("../../static/icons/plans/plus_white.svg")
                no-repeat center center;
              height: 15px;
              width: 15px;
            }
          }
        }

        .laius > p,
        .title,
        .price > * {
          color: $white !important;
        }

        .title {
          color: $white;
        }
      }

      &.disabled {
        transform: scale(1);

        @media screen and (min-width: $screen-lg) {
          transform: scale(1.08);
        }
      }

      &.card-trial-mobile {
        width: calc(100% - 30px) !important;
        margin: 0 15px;
      }

      &.active {
        background: $blue-petroleum;
        transform: scale(1);

        @media screen and (min-width: $screen-lg) {
          transform: scale(1.08);
        }

        .options-list {
          .price-card-item {
          }
          li {
            color: $white;
          }
        }

        .laius {
          p {
          }
        }

        h2 {
          color: $primary;
        }

        .title,
        .price {
          color: $white !important;
        }

        .color-black,
        .title {
          color: $white !important;
        }
      }

      &.active {
        color: white;
        border: 0;
        box-shadow: rgba($primary, 0.2);
      }
    }
  }
}

// Step back
.step-back {
  padding: 0 15px;
  margin-bottom: 20px;
  font-size: rem(20);
  line-height: 1;
  font-family: $font-semi-bold;
  align-items: center;
  gap: 5px;
}

// Card collapse
.card-container-collapse {
  width: 100%;
  padding: 0 30px;

  &[data-expanded="false"] {
    display: none;

    // Force `open` state on desktop
    @media screen and (min-width: $screen-lg) {
      display: block;
    }
  }

  &[data-expanded="true"] {
    display: block;
  }

  // List
  .options-list {
    padding-left: 30px;

    @media screen and (min-width: $screen-lg) {
      padding-left: 0;
    }
  }
}

.compare-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: rem(20);
  margin: 40px 0;
  text-align: center;
  color: $primary;
  font-family: $font-semi-bold;

  @media screen and (min-width: $screen-lg) {
    margin: 40px 0 10px;
  }

  svg {
    margin-top: 10px;
  }
}

// Form-trial
.form-trial {
  .form-group {
    width: 100%;

    @media screen and (min-width: $screen-lg) {
      width: calc(50% - 12px);
    }
  }
}

// List
.pricing-list {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 1.875rem;

  &--title {
    font-family: $font-semi-bold;
    font-size: rem(16);
    line-height: rem(22);
    margin-bottom: rem(5);
    text-transform: uppercase;
    color: $black;
  }

  &--text {
    margin: 0;
    font-size: rem(16);
    line-height: rem(22);

    span {
      font-size: rem(30);
      color: $primary;
      font-family: $font-semi-bold;
    }
  }

  &--separator {
    font-size: rem(14);
    color: #6b7897;
    font-style: italic;
    font-family: $font-light;
  }
}

.pricing-icon {
  width: 50px;
  height: 50px;
  line-height: rem(50);
  border-radius: 50%;
  text-align: center;
  background: $grey-white;
  margin: rem(25) 0 0;

  svg {
    width: 25px;
    height: 25px;
  }
}
