@import './variables.scss';

.sidebar-container {
    left: 0;
    top: 0;
    position: fixed;
    z-index: 100;
    width: $sidebar-width;
    height: 100%;
    padding-left: 35px;

    .wis-logo-container {
        width: 100%;
        padding: 15px 15px 15px 0;
    }

    .nav {
        display: block;

        ul {
            width: 100%;
            li {
                margin-top: 1px;
                height: 60px;
                position: relative;
                margin-bottom: 10px;

                &.sub-menu {
                    height: 40px;
                }

                a {
                    display: flex !important;
                    align-items: center;
                    height: 100%;
                    padding: 0 0 0 20px;
                    font-size: 0.875rem;
                    line-height: 3.5rem;
                    width: 100%;
                    transition: all ease-in-out .25s;

                    &.sub-menu {
                        padding-left: 0;
                        > span {
                            font-family: $font-light !important;
                            font-size: 0.7rem !important;
                        }
                    }

                    span {
                        font-family: $font-semi-bold !important;
                        margin-left: 35px;
                    }

                    &:not(.sub-menu) {
                        &:hover {
                            border-radius: 10px;
                            background-color: $light-blue-grey;
                        }
                        &.active {
                            background-color: $light-blue-grey;
                            border-radius: 10px;
                            & > span {
                                color: $primary;
                            }
                        }
                    }
                    &.submenu,
                    &.active {
                        > span {
                            color: $black;
                            font-family: $font-semi-bold !important;
                        }
                    }
                }
            }
        }
    }
}

a[class^='icon-'] {
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        background-size: contain;
        width: 20px;
        height: 20px;
        display: block;
    }

    &[class*='account'] {
        &::before {
            background: transparent url('../icons/sidebar/account.svg') no-repeat center;
            background-size: contain;
        }
        &.active:before {
            background: transparent url('../icons/sidebar/account-active.svg') no-repeat center;
            background-size: contain;
        }
    }

    &[class*='dashboard'] {
        &::before {
            background: transparent url('../icons/sidebar/dashboard.svg') no-repeat center;
            background-size: contain;
        }
        &.active:before {
            background: transparent url('../icons/sidebar/dashboard-active.svg') no-repeat center;
            background-size: contain;
        }
    }

    &[class*='taskmanager'] {
        &::before {
            background: transparent url('../icons/sidebar/taskmanager.svg') no-repeat center;
            background-size: contain;
        }
        &.active:before {
            background: transparent url('../icons/sidebar/taskmanager-active.svg') no-repeat center;
            background-size: contain;
        }
    }

    &[class*='developer'] {
        &::before {
            background: transparent url('../icons/sidebar/developer.svg') no-repeat center;
            background-size: contain;
        }
        &.active:before {
            background: transparent url('../icons/sidebar/developer-active.svg') no-repeat center;
            background-size: contain;
        }
    }
}
