@import "../../static/scss/variables";
@import "../../static/scss/functions";

input[type="text"],
input[type="email"] {
    font-size: rem(13);
}

input::-webkit-input-placeholder {
    font-size: small;
    opacity: 0.5 !important;
    color: $clear-blue-grey !important;
}
input:focus::-webkit-input-placeholder {
    opacity: 1 !important;
}

label {

    @media screen and (max-width: $screen-lg) {
        font-family: $font-semi-bold;
    }

    &.is-invalid {
        color: red !important;
    }
    sup {
        color: $primary;
        &.is-invalid {
            color: red !important;
        }
    }
}

.form-group {
    .error-container {
        position: relative;
    }
    i {
        position: absolute;
        color: red;
        font-size: rem(13);
    }
}

.input-text {
    border: 1px solid $clear-grey !important;
    height: 40px;
    padding: 11px 13px 11px 44px !important;

    @media screen and (max-width: $screen-lg) {
        font-family: $font-light;
    }

    &.is-invalid {
        color: red;
        &:focus {
            border: 1px solid red !important;
        }
        border: 1px solid red !important;
    }

    &:focus,
    &.input-filled:focus {
        border: 1px solid $clear-blue-grey !important;
    }

    &.input-filled {
        border: 1px solid $clear-blue-grey !important;

        @media screen and (max-width: $screen-lg) {
            border: 1px solid $grey-white !important;
        }
    }

    &.rounded {
        border-radius: 20px !important;
    }
}

.form-control.is-invalid {
    background-image: none !important;
}

//input icons (login, pswd)
div[class^="icon-"] {
    position: relative;

    &::before {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        content: "";
        background-size: contain;
        width: 20px;
        height: 20px;
        display: block;
    }
    &[class*="login"] {
        &::before {
            background: transparent url("../../static/icons/icon-user.svg") no-repeat center;
        }
        &.active::before {
            background: transparent url("../../static/icons/icon-user-active.svg") no-repeat center;
        }
        &.is-invalid::before {
            background: transparent url("../../static/icons/icon-user-invalid.svg") no-repeat center;
        }
    }
    &[class*="email"] {
        &::before {
            background: transparent url("../../static/icons/icon-mail.svg") no-repeat center;
        }
        &.active::before {
            background: transparent url("../../static/icons/icon-mail-active.svg") no-repeat center;
        }
        &.is-invalid::before {
            background: transparent url("../../static/icons/icon-mail-invalid.svg") no-repeat center;
        }
    }
    &[class*="password"] {
        &::before {
            background: transparent url("../../static/icons/icon-password.svg") no-repeat center;
        }
        &.active::before {
            background: transparent url("../../static/icons/icon-password-active.svg") no-repeat center;
        }
        &.is-invalid::before {
            background: transparent url("../../static/icons/icon-password-invalid.svg") no-repeat center;
        }
    }
}

// show password icon
.show-password {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
