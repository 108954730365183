@import './variables';
@import './functions';

.filter-no-collapse {
    height: auto;
    width: 100%;
    background-color: transparent;
    border-radius: 10px;
    color: $clear-blue-grey;
    padding: 0;

    @media screen and (min-width: $screen-lg) {
        height: 80px;
        width: max-content;
        background-color: $light-blue-grey;
        border-radius: 10px;
        padding: 0 15px 0 40px;
    }

    .filter-service-title {
        display: block;
        font-size: rem(20);
        color: $black;
        margin: 0;

        @media screen and (min-width: $screen-lg) {
            display: none;
        }
    }

    div > svg {
        fill: $clear-blue-grey;
    }

    .select-container {
        min-width: 180px;
    }

    .loader-container {
        min-width: 30px;
    }

    svg {
        fill: $clear-blue-grey;
    }

    input[type="date"] {
        border: 1px solid $clear-grey;
        height: 38px;
        width: 180px !important;
        max-width: 180px !important;
        padding-right: 10px;
        color: $clear-blue-grey;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        cursor: pointer;
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url("../icons/icon_calendar-color.svg") no-repeat;
        width: 15px;
        height: 15px;
        border-width: thin;
    }

    span {
        color: $clear-blue-grey;
    }
}

.filter-collapse-container {
    position: relative;
    width: 100%;

    .filter-collapse {
        width: 100%;
        position: absolute;
        z-index: 22;
        min-height: 80px;
        background-color: $light-blue-grey;
        border-radius: 30px;
        color: $clear-blue-grey;
        padding: 0px 15px 0px 40px;

        span {
            color: $clear-blue-grey;
        }

        svg {
            fill: $clear-blue-grey;
        }

        input[type="date"] {
            border: 1px solid $clear-grey;
            height: 38px;
            width: 250px !important;
            max-width: 250px !important;
            padding-right: 10px;
            color: $clear-blue-grey;
        }

        input[type="date"]::-webkit-calendar-picker-indicator {
            cursor: pointer;
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url("../icons/icon_calendar-color.svg") no-repeat;
            width: 15px;
            height: 15px;
            border-width: thin;
        }

        &:hover,
        &.is-open {
            h2 {
                color: $primary;
            }
            svg {
                fill: $primary;
            }
            .collapse-arrow-button {
                > svg {
                    fill: $primary;
                }
            }
        }

        &.is-open {
            .select-container {
                width: 250px;
                position: absolute;
                z-index: 50;
            }
        }

        .collapse-header {
            cursor: pointer;
            height: 80px;

            .badge-filter {
                position: absolute;
                z-index: 10;
                top: 40px;
                left: 51px;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                background-color: $primary;
                border: 1px solid $light-blue-grey;

                span {
                    font-size: 7px;
                    color: $light-blue-grey;
                }
            }
        }

        .collapse-arrow-button {
            height: 20px;
            width: 20px;
            transform: rotate(90deg);
            transition: all 300ms linear;

            > svg {
                fill: $clear-blue-grey;
            }

            &.active {
                transform: rotate(270deg);

                > svg {
                    fill: $primary;
                }
            }
        }

        .filter-content {
            overflow: hidden;
            max-height: 0;
            transition: max-height 300ms cubic-bezier(0, 0.6, 0.6, 1);
        }
    }
}
