@import '../../../static/scss/_variables';
@import '../../../static/scss/_functions';
@import '../../../static/scss/_pagination';

//overides border bottom color for th
@media screen and (min-width: $screen-lg) {
    .table > :not(:last-child) > :last-child > * {
        border-bottom: 1px solid $light-grey !important;
    }
}

// Tables
.table {
    width: 100%;
    border-spacing: 0;
    font-size: 0.875rem;
    table-layout: fixed;
    position: relative;
    font-size: rem(13);

    tbody {
        tr {
            min-height: 60px;
        }

        @media screen and (min-width: $screen-lg) {
            tr:hover {
                background: $row-selected;
            }
        }
    }

    .head-sticky {
        background-color: white;
        position: sticky;
        top: 144px; // header + filter
        z-index: 1;
    }

    thead {
        tr {
            th {
                font-size: rem(13);
                font-family: $font-medium !important;
                color: $main-texts !important;
                font-weight: bold;

                button {
                    font-family: $font-medium;
                    padding: 0;
                    height: 100%;
                }
            }
        }
    }

    td,
    th {
        line-height: 1;
        height: 50px;
        vertical-align: middle;
        padding: 0.5rem;
        border-bottom: 1px solid #eff1f3;
        position: relative;
    }

    th {
        text-align: left;

        &[align='center'] {
            text-align: center;
        }

        &[align='right'] {
            text-align: right;
        }
    }

    input[type='checkbox'] {
        width: 18px;
        height: 18px;
        margin: 0;
        vertical-align: middle;
    }

    td {
        &.bold {
            font-weight: bold;
        }
    }
}

// Table padding large
.table-p-lg {
    th,
    td {
        padding: 0 30px 0 47px;
    }
}

.table-data {
    max-width: 100%;
    table-layout: fixed;

    &.table-data-full {
        max-width: 100%;
        th {
            width: 15vw !important;

            &.th-small {
                width: 8vw !important;
            }
        }
    }

    @media screen and (min-width: $screen-lg) {
        max-width: 500px;
    }

    tbody tr:hover {
        background: none;
    }

    th,
    td {
        min-height: 50px;
        line-height: 1.1875rem;
    }

    th {
        padding: 0;
    }

    td {
        padding: 0 13px;
    }

    tr:last-child {
        th,
        td {
            border: 0;
        }
    }

    + .table-data {
        margin-left: 0;

        @media screen and (min-width: $screen-lg) {
            margin-left: 20px;
        }
    }
}

.table-link {
    cursor: pointer;
    padding: 0;

    span {
        white-space: nowrap;
    }

    @media screen and (min-width: $screen-lg) {
        display: flex;
        align-items: center;
        padding: 0 30px;
        position: absolute;
        width: 100%;
        height: 100%;
        line-height: 1.125rem;
        top: 0;
        left: 0;
        padding: 0.5rem;
        white-space: nowrap;
        cursor: default;
        pointer-events: none;
    }
}

td[align='center'] {
    .table-link {
        justify-content: center;
    }
}

td[align='right'] {
    .table-link {
        justify-content: flex-end;
    }
}

.table-empty-val {
    opacity: 0.3;
}

.table-striped {
    tbody tr:hover {
        background: none;
    }

    tbody tr:nth-of-type(even) {
        background: #f9f9f9;
    }
}

// Clickable row
.clickable-row {
    cursor: pointer;
}

// hr
.table-hr {
    border-top: 1px solid #eff1f3;
    margin: 30px auto;
    max-width: 720px;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

// Layout
.table-layout-fixed {
    table-layout: fixed;
}

// Row checked
.table tbody tr.row-checked {
    background: $row-selected;
}

// Table arrow filter
.btn-table-arrow {
    height: 20px;
    margin: 0;
    background: none;
    vertical-align: middle;
    font-family: $font-primary;
    color: $main-texts;
    background-color: white;
    font-weight: bold;

    @media screen and (min-width: $screen-lg) {
        margin-right: 5px;
    }

    @media screen and (max-width: $screen-lg) {
        min-width: inherit;
        height: 40px !important;
        padding: 0 15px !important;
        border-radius: 30px;
    }

    svg {
        width: 20px;
        height: 24px;
        margin-left: 5px;
        vertical-align: inherit;
        opacity: 0.5;
        transition: transform 0.2s ease, opacity 0.3s ease;

        @media screen and (min-width: $screen-lg) {
            width: 12px;
            height: 12px;
        }
    }

    &.active {
        @media screen and (max-width: $screen-lg) {
            background: $primary;
            color: white;

            svg {
                fill: white;
            }
        }

        svg {
            opacity: 1;
        }
    }

    &:hover {
        svg {
            opacity: 1;
        }
    }

    &.btn-desc {
        svg {
            transform: rotate(180deg);
        }
    }
}

// Animation
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

// No data
.no-table-data {
    position: absolute;
    top: 0;
    left: 0;
    background: $white;
    width: 100%;
    height: 100%;
    color: $grey;
    z-index: 1;

    span {
        animation: fadeIn 0.5s ease 1;
    }
}

// Table historic
.table-historic {
    th,
    td {
        padding: 0 15px;
    }

    td {
        font-size: 0.75rem;
        word-wrap: break-word;
    }
}

// Historic container btn
.historic-btn-container {
    height: 38px;
}

.inline-item {
    min-width: 10vw;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    &.w-30 {
        width: 30vw;
    }
    &.w-20 {
        width: 20vw;
    }
}

// Table responsive
.table-manager {
    @media screen and (max-width: $screen-lg) {
        table-layout: fixed;

        [data-mobile='true'] {
            display: none !important;
        }

        thead {
            tr {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }

            th {
                width: auto !important;
                border: 0;
                padding: 0;
                line-height: 40px;
            }
        }

        tbody {
            padding-top: 10px;
            display: block;

            tr {
                position: relative;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                height: 90px;
                width: 100%;
                border: 2px solid $grey-white;
                padding: 10px 65px;
                border-radius: 10px;

                &:after {
                    content: '';
                    width: 14px;
                    height: 40px;
                    position: absolute;
                    top: 50%;
                    right: 25px;
                    transform: translateY(-50%);
                    background: url('../../../static/icons/icon-next-table.svg') no-repeat center center;
                    background-size: contain;
                }
            }

            tr + tr {
                margin-top: 5px;
            }

            td {
                display: block;
                width: 100%;
                border: 0;
                height: auto;
                padding: 0;
                position: static;

                &[data-service='watermark'],
                &[data-service='anonymization'],
                &[data-service='faces-attributes'] {
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    text-indent: -99999px;
                    width: 40px;
                    height: 40px;
                }

                &[data-service='watermark'] {
                    background: url('../../../static/icons/icon-watermark-table.svg') no-repeat center center;
                    background-size: contain;
                }

                &[data-service='anonymization'] {
                    background: url('../../../static/icons/icon-anonymization-table.svg') no-repeat center center;
                    background-size: contain;
                }
                &[data-service='faces-attributes'] {
                    background: url('../../../static/icons/icon-faces-attributes-table.svg') no-repeat center center;
                    background-size: contain;
                }

                &[data-status='true'] {
                    .table-link {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                    }
                }
            }

            .task-status-badge {
                position: absolute;
                top: 7px;
                right: 7px;
                width: 15px;
                height: 15px;

                + span {
                    display: none;
                }
            }

            .file-name {
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-family: $font-light;
            }
        }
    }

    tbody tr.empty-table {
        border: 0;

        &:after {
            content: '';
            display: none;
        }

        > td {
            position: static;
            height: 190px;
            padding: 1rem;
            text-align: center;
            font-size: rem(20);
            line-height: rem(30);
            color: rgba($main-texts, 0.3);
            border: 0;

            @media screen and (min-width: $screen-lg) {
                height: 100px;

                &:hover {
                    background: white;
                }
            }
        }
    }
}

// Table invoice
.table-invoice {
    tbody tr.empty-table {
        border: 0;

        > td {
            position: static;
            height: 190px;
            padding: 1rem;
            text-align: center;
            font-size: rem(20);
            line-height: rem(30);
            color: rgba($main-texts, 0.3);
            border: 0;

            @media screen and (min-width: $screen-lg) {
                height: 100px;

                &:hover {
                    background: white;
                }
            }
        }
    }

    @media screen and (max-width: $screen-lg) {
        thead,
        [data-mobile='true'] {
            display: none;
        }

        tbody {
            tr {
                position: relative;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                padding: 20px;
                width: 100%;
                height: 90px;
                border: 1px solid darken($grey-white, 5%);
                border-radius: 10px;

                td {
                    border: 0;
                    height: auto;
                    padding: 0;
                    font-family: $font-light;
                    font-size: rem(13);

                    &:first-child {
                        font-family: $font-medium;
                        padding-right: 3px;
                    }

                    &:nth-last-child(2) {
                        flex-basis: 100%;
                        max-width: 275px;
                    }

                    &:last-child {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        width: 100%;
                        z-index: 1;

                        > div {
                            width: 100px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        svg {
                            width: 20px;
                            height: 20px;
                            fill: $clear-blue-grey;
                        }
                    }
                }

                + tr {
                    margin-top: 10px;
                }
            }
        }
    }
}

// Hide pagination
.hidden-pagination + .pagination {
    display: none !important;
}
