@import '../../static/scss/variables';
@import '../../static/scss/functions';

.button {
    padding: 0 12px;
    border-radius: 8px;
    min-width: 140px;
    border: none;
    font-size: rem(13);

    &.bordered {
        border: 1px solid white;
    }

    &:disabled {
        box-shadow: none;
        opacity: 0.2;
    }

    &:focus {
        outline: 0;
        box-shadow: none;
    }

    &.btn-main {
        color: $white;
        line-height: rem(38);
        height: 40px;
        background-color: $primary;
        border: 0; // Prevent pink border on top if background is a gradient

        &:hover:not(:disabled) {
            background-color: $secondary;
        }

        &:active:not(:disabled) {
            color: $primary;
            background-color: white;
            border: 1px solid $primary;
        }

        &:disabled {
            opacity: 1;
            color: $blue-grey;
            background-color: $very-clear-grey;
            border: 1px solid $very-clear-grey;
            cursor: not-allowed;

            &.grey-dark {
                opacity: 0.5;
                background-color: $clear-blue-grey;
            }
        }
    }

    &.btn-main-inverted {
        color: $white;
        background: $gradient-main;
        height: 40px;

        &:hover {
            background: none;
            background-color: $primary !important;
        }

        &:disabled {
            opacity: 1;
            background-color: $light-blue-grey;
        }
    }

    &.btn-disconnect {
        min-height: inherit;
        min-width: inherit;
        background: url(../../static/icons/icon_disconnect.svg) no-repeat center;

        &:hover {
            background: url(../../static/icons/icon_deconnexion-white.svg) no-repeat center;
            background-color: $primary;
            background-size: 21px;
        }

        margin: auto;
        border-radius: 50% !important;
        height: 60px !important;
        width: 60px !important;
        padding: 0;
        background-size: 20px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
    }

    &.btn-close {
        min-height: inherit;
        min-width: inherit;
        background: url(../../static/icons/icon_close.svg) no-repeat center;
        transition: all 300ms ease;

        &:hover {
            background: url(../../static/icons/icon_close-white.svg) no-repeat center;
            background-color: $primary;
            background-size: 21px;
        }

        margin: auto;
        height: 20px !important;
        width: 30px !important;
        padding: 0;
        background-size: 20px;
        transform: translate(10px, -10px);

        @media screen and (min-width: $screen-lg) {
            height: 40px !important;
            width: 40px !important;
            border: 1px solid $primary;
            border-radius: 50% !important;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
            transform: none;
        }
    }

    &.btn-white-main {
        height: 40px;
        color: $primary;
        background-color: $white;
        border: 1px solid $light-grey;
        box-shadow: none !important;

        &:hover:not(:disabled) {
            background: $secondary;
            color: $white !important;
            border: 1px solid $secondary;
        }

        &:active:not(:disabled) {
            background-color: $active;
        }

        &:disabled {
            opacity: 1;
            background: none;
            background-color: $light-blue-grey;
        }
    }

    &.collapse-btn {
        background: url('../../static/icons/right-arrow.svg') no-repeat center;
        height: 25px;
        width: 25px;
        min-width: 0;
        padding: 0 !important;
        box-shadow: none !important;
        transform: rotate(90deg);
        transition: all 300ms linear;

        &.active {
            transform: rotate(270deg);
            background: url('../../static/icons/right-arrow-active.svg') no-repeat center;
        }
    }

    &.btn-collapse {
        display: block;
        min-width: auto !important;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        padding: 0;
        transition: background 0.1s ease;

        @media screen and (min-width: $screen-lg) {
            display: none;
        }

        svg {
            width: 30px;
            height: 30px;
            transform: scale(1);
            transition: transform 0.1s ease;
        }

        &.expanded {
            background: $primary;

            svg {
                transform: scale(-1);
            }
        }

        &.btn-collapse-card {
            position: absolute;
            top: 35px;
            right: 15px;
            background: white;
            border: 1px solid $very-clear-grey;

            svg {
                width: 28px;
                height: 28px;
            }

            &[aria-expanded='true'] {
                background: $primary;
                border: 1px solid transparent;

                svg {
                    transform: scale(-1) translateY(1px);
                    fill: white;
                }
            }
        }
    }

    &.btn-filter-mobile {
        width: 40px;
        height: 40px;
        min-width: inherit !important;
        padding: 0;
        border-radius: 50%;
        background: $grey-white;
        transition: background 0.3s ease;

        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
            background: $primary;

            svg {
                fill: white;
            }
        }

        &:disabled {
            opacity: 1;
            background: $grey-white;
        }

        @media screen and (min-width: $screen-lg) {
            display: none;
        }
    }

    &.tab-button {
        box-shadow: none !important;
        border-radius: inherit !important;
        height: 50px !important;
        background-color: $white !important;
        min-width: 120px !important;
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        font-size: rem(16) !important;
        color: $clear-blue-grey;
        border-bottom: 2px solid $light-blue-grey;

        &.active {
            font-size: rem(16) !important;
            color: $primary;
            font-family: $font-semi-bold !important;
            border-bottom: 2px solid $primary;
        }

        @media screen and (min-width: $screen-lg) {
            height: 50px !important;
            min-width: 150px !important;
            font-size: rem(18) !important;

            &:hover {
                color: $primary;
                font-size: rem(22) !important;
                font-family: $font-semi-bold;
                border-bottom: 3px solid $primary;
            }

            &.active {
                font-size: rem(22) !important;
                border-bottom: 3px solid $primary;
            }
        }

        &.payment {
            background-color: $grey-white !important;
        }
    }

    &.btn-account {
        min-width: inherit;
        min-height: inherit;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        background-color: $green;
        color: $white;
        border: 1px solid $light-grey;
        font-family: $font-semi-bold;

        @media screen and (min-width: $screen-lg) {
            height: 60px;
            width: 60px;
        }

        &:hover {
            background-color: #33e394;
        }
    }

    &.btn-deco {
        display: block;
        width: 100%;
        font-size: rem(15);
        line-height: rem(53);
        color: $clear-blue-grey;
        background: rgba($very-clear-grey, 0.56);
        border-radius: 5px;

        &:before {
            content: '';
            display: inline-block;
            width: 14px;
            height: 16px;
            background: url('../../static/icons/icon_disconnect.svg') no-repeat center;
            background-size: contain;
            margin-right: 5px;
            transform: translateY(2px);
        }
    }

    &.btn-menu {
        min-width: inherit;
        border-radius: 0;
        padding: 0;
        background: 0;

        width: 45px;
        height: 45px;
        text-align: center;
        position: relative;

        &:before {
            content: '';
            width: 26px;
            height: 26px;
            display: inline-block;
            background: url('../../static/icons/icon-menu.svg') no-repeat center center;
            background-size: contain;
        }

        &.active:before {
            background: url('../../static/icons/icon-menu-close.svg') no-repeat center center;
            background-size: contain;
        }
    }

    &.btn-task {
        position: relative;
        padding: 0 45px 0 10px;
        height: 55px;
        width: 170px;
        min-width: inherit;
        border-radius: 60px;
        background: $primary;
        text-align: center;
        display: block;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
        transition: width 0.25s ease;

        @media screen and (min-width: $screen-lg) {
            display: none;
        }

        &.active,
        &.scroll-down.active {
            width: 170px;
            background: $blue-petroleum;

            .button-text {
                opacity: 1;
                white-space: nowrap;
            }

            .icon {
                background: white;

                svg {
                    fill: $blue-petroleum;
                }
            }
        }

        &.white {
            background: white;
            width: auto;
            min-width: auto;
            line-height: 55px;
            padding: 0 50px 0 10px;

            .icon {
                background: $primary;

                svg {
                    width: 25px;
                    height: 25px;
                }
            }

            .button-text {
                color: $primary;
            }
        }

        .button-text {
            font-size: rem(13);
            color: white;
            transition: opacity 0.25s ease 0.25s;
        }

        &.scroll-down {
            width: 55px;

            .button-text {
                opacity: 0;
                transition: opacity 0.1s ease;
            }

            .icon {
                background: $primary;

                svg {
                    fill: white;
                }
            }
        }

        .icon {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: white;
            position: absolute;
            top: 10px;
            right: 10px;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.btn-squarred {
        background: $gradient-main;
        font-size: rem(15);
        color: white;
        border-radius: 7px;
        line-height: rem(23);
        height: 65px;
        min-width: 200px;
        display: flex;
        align-items: center;

        &:hover {
            background: none;
            background-color: $primary;
        }

        &::before {
            content: '';
            left: 50px;
            background-size: contain;
            width: 35px;
            height: 35px;
            display: block;
            margin-right: 20px;
        }

        &.custom-disabled {
            opacity: 0.2;
            border-radius: 7px !important;
        }

        &[class*='anonymization'] {
            &::before {
                background: transparent url('../../static/icons/tasks/anonymization.svg') no-repeat center;
            }
        }

        &[class*='watermark'] {
            &::before {
                background: transparent url('../../static/icons/tasks/watermark.svg') no-repeat center;
            }
        }
    }

    &.btn-mobile-task {
        position: relative;
        height: 95px;
        width: 50%;
        min-width: auto;
        border-radius: 5px;
        background: $grey-white;
        color: $clear-blue-grey;
        font-size: rem(13);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background: url('../../static/icons/icon-mobile-task-dl.svg') no-repeat center center;
            background-size: contain;
            margin-bottom: 2px;
        }

        svg {
            width: 100%;
            height: 100%;
            position: absolute;
            fill: none;
            opacity: 0;
            top: 0;
            left: 0;
            z-index: 1;
        }

        &:disabled {
            pointer-events: none;
            opacity: 0.3;
        }
    }

    &.btn-desktop-task {
        font-size: rem(12);
        color: white;
        border-radius: 10px;
        line-height: rem(15);
        height: 55px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        text-align: left;
        background-color: $primary;
        font-family: $font-medium !important;
        transition: all ease-in-out 0.25s;

        &::before {
            content: '';
            left: 50px;
            background-size: contain;
            width: 35px;
            height: 35px;
            display: block;
            margin-right: 20px;
        }

        &:hover:not(:disabled) {
            background: $blue-petroleum;
        }

        &:active:not(:disabled) {
            background: $active;
        }

        &[class*='anonymization'] {
            &::before {
                background: transparent url('../../static/icons/tasks/icon-anonymization.svg') no-repeat center;
                background-size: 25px;
            }
        }

        &[class*='watermark'] {
            &::before {
                background: transparent url('../../static/icons/tasks/icon-watermark.svg') no-repeat center;
                background-size: 25px;
            }
        }
        &[class*='faces-attributes'] {
            &::before {
                background: transparent url('../../static/icons/tasks/icon-faces-attributes.svg') no-repeat center;
                background-size: 25px;
            }
        }
    }
}

.modal {
    .btn {
        padding: 7px 12px 7px 12px;
        border-radius: 10px !important;
        min-width: 140px;
        border: none;
        font-size: rem(13) !important;
        color: $white;

        &.btn-main {
            color: $white;
            background-color: $primary;
            border-color: inherit !important;
            box-shadow: none !important;

            &:hover:not(:disabled) {
                background: $secondary;
                color: $white !important;
            }

            &:active:not(:disabled) {
                background-color: $active;
            }

            &:disabled {
                opacity: 1;
                background: none;
                background-color: $light-blue-grey;
            }
        }

        &.btn-white-main {
            text-decoration: none !important;
            color: $primary;
            background-color: $white;
            border: 1px solid $light-grey;

            &:hover:not(:disabled) {
                background: $secondary;
                color: $white !important;
            }

            &:active:not(:disabled) {
                background-color: $active;
            }

            &:disabled {
                opacity: 1;
                background: none;
                background-color: $light-blue-grey;
            }
        }
    }
}

// Button-grp
.button-grp {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    &.justify-start {
        justify-content: flex-start;
    }

    &.justify-end {
        justify-content: flex-end;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-between {
        justify-content: space-between;
    }

    &.justify-around {
        justify-content: space-around;
    }

    &.justify-evenly {
        justify-content: space-evenly;
    }
}
