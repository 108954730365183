@import "./variables";
@import './functions';

$consumption: #33E394;

.activation-container {
    background-color:transparent;

    @media screen and (min-width: $screen-lg) {
        background-color: $light-blue-grey;
        border-radius: 25px;
        padding: 2px 15px 2px 30px;
    }

    &.inactive {
        background-color: transparent;
        padding-left: 0;
    }

    label {
        font-family: $font-primary;
        color: $clear-blue-grey;
        line-height: 0;
        font-size: rem(10);
        padding-right: 2px;

        @media screen and (min-width: $screen-lg) {
            font-family: $font-medium;
            font-size: rem(13);
            padding-right: 0;
        }
    }

    span {
        font-size: rem(10);
        color: $clear-blue-grey;

        @media screen and (min-width: $screen-lg) {
            font-size: rem(12);
        }
    }
}

.custom-badge {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: $clear-grey;
    margin: auto;

    &.big {
        height: 30px;
        width: 30px;
    }

    &.active {
        background-color: $consumption !important;
    }
}

.blue-grey-title {
    color: $clear-blue-grey;
}

.select-container {
    min-width: 180px;
}

.inner-card {
    transition: background-color 0.3s ease-in-out;
    border: 2px solid $light-blue-grey;
    border-radius: 10px;
    padding: 5% 0;
    background-color: $white;
    min-height: 80px;

    &.calendar {
        height: 400px;
        border: 0;

        @media screen and (min-width: $screen-lg) {
            border: 2px solid $light-blue-grey;

            &[hidden] {
                display: block !important;
            }
        }
    }

    @media screen and (min-width: $screen-lg) {
        min-height: auto;

        &.hovered {
            background-color: $light-blue-grey;
        }
    }

    h3 {
        &.title {
            font-size: rem(10);

            @media screen and (min-width: $screen-lg) {
                font-size: rem(15);
            }
                &.total {
                    text-align: center;
                    font-size: rem(10);

                    @media screen and (min-width: $screen-lg) {
                        color: $clear-blue-grey !important;
                        text-align: left;
                        font-size: rem(15);
                }
            }
        }
    }

    span {
        font-size: rem(12);

        @media screen and (min-width: $screen-lg) {
            font-size: rem(15);
        }

        &.consumption {
            color: $consumption;
        }

        &.apiCalls {
            color: $pink;
        }
    }

    .data {
        font-size:rem(23);
        line-height: 1;

        &.small-number {
            font-size: rem(18);
        }

        @media screen and (min-width: $screen-lg) {
            font-size: rem(40);
            font-weight: bold;

            &.small-number {
                font-size: rem(40);
                font-weight: bold;
            }
        }

        &.consumption {
            color: $consumption;
        }

        &.apiCalls {
            color: $pink;
        }
    }
}

div[class^="dashboard-label-"] {
    height: 30px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -30px;
        transform: translateY(-50%);
        background-size: contain;
        width: 20px;
        height: 20px;
        display: block;
    }

    @media screen and (min-width: $screen-lg) {
        margin-left: 30px;

        &[class*="active"] {

            &:before {
                border-radius: 50% !important;
                height: 20px;
                width: 20px;
                left: 5px;
                background: transparent url("../icons/services/icon_activate.svg") no-repeat center;
            }
        }

        &[class*="inactive"] {

            &:before {
                border-radius: 50% !important;
                height: 20px;
                width: 20px;
                left: -25px;
                background: transparent url("../icons/services/icon_deactivated.svg") no-repeat center;
            }
        }
    }
}
