@import '../../../static/scss/variables';
@import '../../../static/scss/functions';

/*
    TASK CONTAINER
*/

.task-actions-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 10;

    .task-list {
        padding: 0;
        margin: 0;
        position: absolute;
        right: 0;
        bottom: 55px;
        width: 170px;
        transition: 200ms transform ease-in-out;
        transform: translateX(calc(100% + 15px));
        &.show {
            transform: translateX(0);
        }

        li {
            position: relative;
            margin-bottom: 10px;
        }
    }
}
