@import './_variables';
@import './functions';

.task-manager-container {
    box-shadow: none;
    border-radius: 30px;
    padding: 0 0 20px 0;

    @media screen and (min-width: $screen-lg) {
        border-radius: 0;
        padding: 0;
    }

    /*
        z-index in task-manager : 
        -1: white before hiding scroll
        0: tab content
        1: sticky tab header 
        2: fixed menu
    */

    .menu {
        z-index: 2;
        width: 100%;
        display: flex;
        position: fixed;
        top: $header-height;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 65px;
            width: 100%;
            z-index: -1;
            background-color: white;
        }

        // if there is a menu in the page, it changes top sticky anchor for table head
        + .middle-container .table .head-sticky {
            top: 70px;

            @media screen and (min-width: $screen-lg) {
                top: 175px;
            }
        }

        .tasks {
            margin-right: auto;
            display: flex;
            padding-top: 3px;
        }

        .filters {
            width: 60%;
            margin-left: 2%;
            background-color: $grey-white;
            border-radius: 10px;
            padding: 1.2rem 1rem;
            position: relative;

            .filters-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $clear-blue-grey;

                .filters-title {
                    display: flex;
                    align-items: center;
                    h2 {
                        margin-bottom: 0;
                    }
                    &:before {
                        content: '';
                        left: 50px;
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        margin-right: 20px;
                        background-color: $clear-blue-grey;
                        mask-size: contain;
                        mask-image: url('../../static/icons/icon_filters-grey.svg');
                        mask-repeat: no-repeat;
                    }
                    &.open {
                        background-color: $primary;
                        color: $primary;
                    }
                }
                .arrow-collapse {
                    width: 20px !important;
                    height: 20px !important;
                    min-width: inherit;
                    display: inline-block;
                    margin-right: 10px;
                    transform: rotate(90deg);
                    mask-size: contain;
                    background-color: $clear-blue-grey;
                    mask-image: url('../../static/icons/right-arrow.svg');
                    mask-repeat: no-repeat;
                    transition: all 500ms ease-in-out;
                    &.open {
                        background-color: $primary;
                        transform: rotate(-90deg);
                    }
                }
                &:hover {
                    color: $primary;
                    cursor: pointer;
                    .arrow-collapse {
                        background-color: $primary;
                    }
                    .filters-title {
                        &:before {
                            background-color: $primary;
                        }
                    }
                }
            }
            .filters-grid {
                height: 0;
                overflow: hidden;
                display: grid;
                grid-template-columns: 2fr 2fr 1fr;
                grid-gap: 10px;

                > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                svg {
                    fill: $clear-blue-grey;
                }

                input[type='date'] {
                    border: 1px solid $clear-grey;
                    height: 38px;
                    width: 80%;
                    color: $blue-grey;
                    border-radius: 3px;
                    font-family: $font-light;
                    padding-left: 2%;
                    font-style: italic;
                    font-size: rem(12);
                    padding-right: 2%;
                    color: $clear-grey;
                }
                input[type='date'].has-value {
                    color: $clear-blue-grey;
                    font-size: rem(14);
                    font-style: inherit;
                }
                input[type='date']::-webkit-calendar-picker-indicator {
                    cursor: pointer;
                    color: rgba(0, 0, 0, 0);
                    opacity: 1;
                    display: block;
                    background: url('../icons/icon_calendar-color.svg') no-repeat;
                    width: 15px;
                    height: 15px;
                    border-width: thin;
                }
                span {
                    color: $clear-blue-grey;
                }

                * > .select-container {
                    width: 80%;
                    font-size: rem(14) !important;
                }

                .column-1 {
                    grid-row: 1;
                }
                .column-2 {
                    grid-row: 1;
                }
                .column-3 {
                    grid-row: 1;
                    justify-content: flex-end !important;
                }
            }
        }
    }

    .task-status-badge {
        width: 10px;
        height: 10px;
        border-radius: 50%;

        &.none {
            background-color: $clear-grey;
        }
        &.processing {
            background-color: $processing;
        }
        &.success {
            background-color: $success;
        }
        &.failed {
            background-color: $error;
        }
    }

    .task-page {
        h3 {
            font-family: $font-semi-bold;
            font-size: rem(18) !important;
            color: $clear-blue-grey;
        }

        .image-processing-info {
            display: flex;
            align-items: baseline;
            justify-content: center;
            gap: 2rem;
            margin-bottom: 2rem;
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                svg {
                    height: 50px;
                }
                span {
                    color: $main-texts!important;
                    font-size: rem(16);
                }
            }
        }

        .image-processing {
            white-space: pre-line;
            font-size: 0.7rem !important; // PARCE QU'UNE CHART GRAPHIQUE NE SERT A RIEN PUISQUE FREDERIC!!!
            font-family: $font-light;
            color: $clear-blue-grey;
            text-align: center;
        }

        .file-container {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 20px;
            border: 1px solid transparent;
            align-items: center;
            border-radius: 15px;
            cursor: pointer;

            @media screen and (min-width: $screen-lg) {
                height: 160px;
                padding: 10px 50px;
                justify-content: space-between;
                border: 1px solid $border-grey;
                flex-direction: row;
            }

            h3,
            span {
                color: $clear-text;
            }

            &.bordered {
                border: 1px solid transparent;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

                @media screen and (min-width: $screen-lg) {
                    border: 1px solid $primary;
                    box-shadow: none;
                }
            }

            &.selected {
                border: 1px solid transparent;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

                @media screen and (min-width: $screen-lg) {
                    border: 1px solid $primary;
                }

                h3 {
                    color: $primary;
                }

                span {
                    color: $clear-blue-grey !important;
                }
            }

            &-title {
                min-height: 45px;
            }

            &-laius,
            &-laius span {
                vertical-align: middle;
                align-items: center;
                font-family: $font-light;
                font-size: rem(11);
                line-height: rem(16);
                margin-top: 15px;
                min-height: 50px;

                &-flex {
                    display: flex;

                    @media screen and (min-width: $screen-lg) {
                        display: inline;
                    }
                }

                @media screen and (min-width: $screen-lg) {
                    font-family: $font-primary;
                    font-size: rem(13);
                    line-height: rem(20);
                    margin: 0;
                    min-height: auto;
                }
            }

            button {
                width: 100%;

                @media screen and (min-width: $screen-lg) {
                    width: 120px;
                }
            }

            .picture-container {
                padding: 25px;
                border-radius: 25px;
                width: 100%;
                height: 125px;
                border: 0;
                background: $grey-white;

                > img {
                    border-radius: 20px;
                    object-fit: cover !important;
                }

                @media screen and (min-width: $screen-lg) {
                    height: 120px;
                    width: 120px;
                    padding: 25px;
                    border: 3px dashed;
                    background: 0;

                    > img {
                        border-radius: 0;
                        object-fit: contain !important;
                    }
                }

                &.preview {
                    border: 0;
                    padding: 0;

                    .img-preview {
                        height: 100px;
                        width: 100px;
                    }
                }
            }

            .error-container {
                width: 100px;
                height: 100px;
            }
        }

        .options-container {
            color: $clear-blue-grey;

            .preview {
                height: 305px;
                max-height: 305px;
                position: relative;
                background-color: $light-blue-grey;
                border-radius: 15px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                *:not(.preview-image) {
                    position: relative;
                    z-index: 1;
                }

                .selected-background {
                    object-fit: cover;
                    width: auto;
                    height: 100%;
                    max-width: 100%;
                    max-height: 305px;
                }

                @media screen and (min-width: $screen-lg) {
                    height: 400px;
                    max-height: 400px;

                    .selected-background {
                        max-height: 400px;
                        image-orientation: from-image;
                    }
                }

                &.file-selected {
                    h3 {
                        color: $primary;
                    }

                    .preview-image {
                        height: 100%;
                        margin: 0;
                        position: absolute;

                        @media screen and (max-width: $screen-lg) {
                            + span,
                            + span + span {
                                color: white !important;
                                text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
                            }
                        }

                        + .color-white,
                        + .color-white + .color-white {
                            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
                        }
                    }
                }

                .preview-image {
                    height: auto;
                    width: 100%;
                    border-radius: 15px;
                    margin-bottom: 15px;
                    overflow: hidden;
                    top: 0;
                    left: 0;
                    z-index: 0;

                    > img {
                        border-radius: 15px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    @media screen and (min-width: $screen-lg) {
                        position: static;
                        height: 75%;
                        border-radius: 0;

                        > img {
                            border-radius: 0;
                            width: auto;
                            height: auto;
                            object-fit: unset;
                        }
                    }

                    .picture-container {
                        border: 3px dashed $clear-text;
                        border-radius: 25px;
                        height: 100px;
                        width: 100px;
                        &.preview {
                            border: 0;
                            padding: 0;
                            img {
                                height: 100px;
                                width: 100px;
                            }
                        }
                    }
                }
            }

            .information-panel {
                display: none;
                position: relative;
                background: $clear-blue-grey;
                color: white;
                font-family: $font-light;
                font-size: rem(11);
                line-height: rem(16);
                text-align: center;
                padding: 10px;
                border-radius: 10px;

                &:before {
                    content: '';
                    width: 14px;
                    height: 14px;
                    background: $clear-blue-grey;
                    position: absolute;
                    top: 0;
                    right: 50%;
                    transform: translate(32px, -50%) rotate(45deg);
                }

                &[data-visible='true'] {
                    display: block;
                    transform: translateY(10px);
                }
            }

            .options {
                span {
                    color: $clear-text;
                    font-family: $font-primary;
                }

                h3,
                i {
                    color: $clear-text;
                }

                &.file-selected {
                    h3 {
                        color: $primary;
                    }

                    span,
                    i {
                        color: $clear-blue-grey;
                    }
                }

                .option-grid {
                    display: grid;
                    grid-template-columns: 1fr 2fr 2fr;
                    grid-gap: 0.5rem;

                    .information {
                        flex-basis: inherit;
                        width: 15px;
                        height: 15px;
                        border: 1px solid $clear-blue-grey;
                        border-radius: 50%;
                        color: $clear-blue-grey;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        position: relative;

                        @media screen and (max-width: $screen-lg) {
                            flex-basis: 20px;

                            &.active {
                                background: $clear-blue-grey;
                                border: 1px solid $clear-blue-grey !important;

                                small {
                                    font-size: rem(10);
                                    color: white;
                                }
                            }
                        }

                        small {
                            font-size: rem(10);

                            @media screen and (min-width: $screen-lg) {
                                font-size: rem(12);
                            }
                        }

                        .tooltip-container {
                            position: absolute;
                            background-color: black;
                            color: white;
                            border-radius: 10px;
                            padding: 10px;
                            top: -70px;
                            left: -230px;
                            display: inline;
                            width: 500px;
                        }

                        &.inactive,
                        &.disabled {
                            border: 1px solid $clear-text;
                            color: $clear-text;
                        }
                    }

                    .toolt span.active {
                        color: $clear-blue-grey;
                    }
                    span.disabled {
                        color: $clear-text;
                    }

                    svg.active {
                        fill: $clear-blue-grey;
                    }
                    svg.inactive {
                        fill: $clear-blue-grey;
                    }
                    svg.disabled {
                        fill: $clear-text;
                    }
                }

                .check-box-container {
                    cursor: pointer;
                    padding: 40px;
                    border: 1px solid $border-grey;
                    border-radius: 15px;

                    &.file-selected {
                        &:hover {
                            border: 1px solid $primary;
                        }
                        &.option-selected {
                            span {
                                font-family: $font-medium;
                                color: $main-texts;
                            }
                        }
                    }

                    .checkbox {
                        width: 15px;
                        height: 15px;
                        border: 1px solid $clear-text;
                        border-radius: 2px;
                    }
                }
            }
        }
    }
}

#container {
    position: relative;
    max-height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}
