@import "./_variables.scss";
@import './functions';

// page tab in accountLayout.tsx
.page-header {
    background-color: white;
    position: sticky;
    width: calc(100% + 24px);
    top: 69px; // prevent half pixel
    margin-left: -12px;
    z-index: 10;

    > div {
        width: 100%;
    } 
    
    @media screen and (min-width: $screen-lg) {
        top: 96px;
        width: 100%;
        margin-left: 0;

        > div {
            width: auto;
        }
    }
}

// Middle-container
.page-container .middle-container {

    &.middle-container-services {

        padding: 20px;
        box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.05);
        border-radius: 10px;

        h2 {
            font-size: rem(16);

            @media screen and (min-width: $screen-lg) {
                font-size: rem(22);
            }
        }
    }
}

.services-management-container {
    color: $main-texts;

    table > thead > tr > th {
        border-bottom: 0 !important;
        height: 30px;
    }

    table {
        margin-bottom: 0;

        thead {
            display: none;

            @media screen and (min-width: $screen-lg) {
                display: table-header-group;
            }
        }

        tr {
            display: flex;
            justify-content: space-between;
            width: 100%;
            background: $grey-white;
            padding: 50px 20px 20px;
            border-radius: 20px;
            position: relative;

            th {
                position: absolute;
                top: 20px;
                left: 20px;
                height: auto;
                padding: 0;
                font-size: rem(16);
                font-family: $font-semi-bold;
                background: 0;
            }

            p {
                font-size: rem(23);
                color: $primary;
                font-family: $font-semi-bold;
                margin: 0;

                &.small-number {
                    font-size: rem(18);
                }

                @media screen and (min-width: $screen-lg) {
                    font-size: rem(13);
                    color: $black;
                    font-family: $font-primary;

                    &.small-number {
                        font-size: rem(13);
                    }
                }
            }

            td {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                width: calc((100% / 3) - 10px);
                background: white;
                height: 80px;
                font-size: rem(23);
                color: $primary;
                font-family: $font-semi-bold;
                border-radius: 15px;

                &:before {
                    content: attr(data-title);
                    font-size: rem(10);
                    font-family: $font-semi-bold;
                    color: $black;
                    margin-bottom: 10px;
                }

                .data-type {
                    display: block;
                    padding-top: 5px;
                    font-size: rem(12);
                    color: $primary;

                    @media screen and (min-width: $screen-lg) {
                        display: inline;
                    }
                }
            }

            @media screen and (min-width: $screen-lg) {
                display: table-row;
                background: 0;
                padding: 0;
                border-radius: 0;

                th {
                    position: static;
                    font-size: rem(13);
                    font-family: $font-primary;
                }

                td {
                    display: table-cell;
                    font-size: rem(13);
                    color: $black;
                    font-family: $font-primary;
                    width: auto;
                    height: 50px;
                    background: transparent;

                    &:before {
                        content: '';
                    }

                    .data-type {
                        padding: 0;
                        font-size: rem(13);
                        color: $black;
                    }
                }
            }

            @media screen and (max-width: $screen-lg) {
                + tr {
                    margin-top: 15px;
                }
            }
        }

        td,
        th {
            border: 0;
            box-shadow: none;

            @media screen and (min-width: $screen-lg) {
                border-bottom: 1px solid #eff1f3;
            }
        }
    }

    button {
        min-width: 100px !important;
    }

    .service-card {

        label {
            font-family: $font-medium;
            font-size: rem(13);
            color: $main-texts;
        }
        span {
            font-family: $font-primary;
            color: $clear-blue-grey;
        }

        .activation-container {
            background-color: $light-blue-grey;
            border-radius: 25px;
            padding: 2px 30px 2px 15px;

            @media screen and (min-width: $screen-lg) {
                padding: 2px 15px 2px 30px;
            } 

            &.inactive {
                background-color: transparent !important;
                padding: 0 25px 0 0;

                @media screen and (min-width: $screen-lg) {
                    padding-left: 0;
                }
            }

            label {
                font-family: $font-medium;
                color: $clear-blue-grey;
                line-height: 0;
            }

            span {
                font-size: rem(12);
                color: $clear-blue-grey;
            }
        }
    }
}

div[class^="service-label-"] {
    height: 30px;
    position: relative;

    @media screen and (min-width: $screen-lg) {
        margin-left: 30px;
    }

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -30px;
        transform: translateY(-50%);
        background-size: contain;
        width: 20px;
        height: 20px;
        display: block;
    }

    @media screen and (min-width: $screen-lg) {
        // Hide on mobile
        &[class*="date"] {
            &:before {
                background: transparent url("../icons/services/icon_date-activation.svg") no-repeat center;
            }
        }
        &[class*="ending-date"] {
            &:before {
                background: transparent url("../icons/services/icon_date-ending.svg") no-repeat center;
            }
        }
        &[class*="total-call"] {
            &:before {
                background: transparent url("../icons/services/icon_total-call.svg") no-repeat center;
            }
        }
        &[class*="total-identity"] {
            &:before {
                background: transparent url("../icons/services/icon_total-call.svg") no-repeat center;
            }
        }
        &[class*="sdk-type"] {
            &:before {
                background: transparent url("../icons/services/icon_SDK.svg") no-repeat center;
            }
        }
        &[class*="setup"] {
            &:before {
                background: transparent url("../icons/services/icon_setup.svg") no-repeat center;
            }
        }
        &[class*="licence-type"] {
            &:before {
                background: transparent url("../icons/services/icon_license.svg") no-repeat center;
            }
        }
        &[class*="support-type"] {
            &:before {
                background: transparent url("../icons/services/icon_support.svg") no-repeat center;
            }
        }
        &[class*="auth-services"] {
            &:before {
                background: transparent url("../icons/services/icon_authorization.svg") no-repeat center;
            }
        }
    }

    &[class*="active"] {
        &:before {
            border-radius: 50% !important;
            height: 20px;
            width: 20px;
            left: inherit;
            right: 5px;
            background: transparent url("../icons/services/icon_activate.svg") no-repeat center;

            @media screen and (min-width: $screen-lg) {
                left: 5px;
            } 
        }
    }
    &[class*="inactive"] {

        &:before {
            border-radius: 50% !important;
            height: 20px;
            width: 20px;
            left: inherit;
            right: 0;
            background: transparent url("../icons/services/icon_deactivated.svg") no-repeat center;

            @media screen and (min-width: $screen-lg) {
                left: -25px;
                right: inherit;
            }
        }
    }
}

// Collapse
.collapse {

    &-hidden {
        display: none;

        @media screen and (min-width: $screen-lg) {
            display: block;
        }
    }

    &-visible {
        display: block;
    }
}
