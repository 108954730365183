@import "../../static/scss/variables";
@import '../../static/scss/functions';

// Modal
.modal {
    background: $white;
    width: 100% !important;
    padding: 50px 30px !important;
    max-width: 650px !important;
    border-radius: 5px !important;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    max-height: 200px;
    &:focus {
        outline: 0;
    }

    @media screen and (max-width: $screen-lg) {

        h2 {
            font-size: rem(20) !important;
            line-height: rem(30) !important;
            margin: 0 0 10px !important;
        }
    }
}

.modal-message {
    font-family: $font-light;
    font-size: rem(12);
    line-height: rem(18);
    text-align: center;
    margin: 0 0 15px;
    white-space: pre-line !important;

    @media screen and (min-width: $screen-lg) {
        font-family: $font-primary;
        margin: 0 0 15px;
        font-size: rem(13);
        line-height: rem(22);
    }
}
