@import "./variables";
@import "./functions";

.header {
    position: fixed;
    display: flex;
    padding: 0 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    color: $main-texts;
    background-color: $white;
    z-index: 100;

    @media screen and (min-width: $screen-lg) {
        width: calc(100% - #{$sidebar-width});
        left: $sidebar-width;
        padding: 0 3%;
        height: $header-height;
    }

    &:not(.header-task) {

        .btn-header-task {
            display: none;
        }
    }

    &.header-task {

        .btn-header-task {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
        }
    }

    .page-subsection {
        border-radius: 30px;
        padding: 5px 10px;
        color: $clear-blue-grey;
        background-color: $light-blue-grey;
        font-family: $font-semi-bold;
    }

    .trial-section {
        
        .title {
            color: $clear-blue-grey !important;
            font-family: $font-semi-bold;
        }
        .data {
            color: $clear-blue-grey !important;
            font-family: $font-light;
        }
    }
}

.main-title {
    font-size: rem(20);

    @media screen and (min-width: $screen-lg) {
        font-size: rem(30);
    }
}
