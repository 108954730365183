@import "./variables";
@import './_functions';

.error {
    border-radius: 25px;
    padding: 7px 10px 7px 55px;
    font-size: rem(13);
    background: url("../icons/info-error.svg") no-repeat 17px 50%, rgba(208, 2, 27, 0.05);
}

.error-card-expired-account {
    > span {
        color: $error;
    }
}
