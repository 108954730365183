@import '../../../static/scss/variables';
@import '../../../static/scss/functions';

/*
    TASK MANAGER DETAIL
*/

.title-up-container {
    display: flex;
    align-items: center;
    gap: 10px;

    font-size: rem(20);
    font-family: $font-semi-bold;
    margin-bottom: 25px;
}

.middle-container {
    
    &.middle-container-detail {
        border-radius: 30px;
        padding: 15px 20px 30px 20px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    
        .middle-container-detail-list {
            width: 100%;
            margin: 0;
            padding: 0;
    
            li {
                height: 70px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                font-size: rem(13);
                font-family: $font-light;
                word-break: break-all;
                padding: 5px 0;

                &:not(:last-child) {
                    border-bottom: 1px solid $grey-white;
                }

                &.item-buttons {
                    padding: 20px 0 0 0;
                    height: auto;
                }
            }
    
            .list-title {
                width: 100%;
                font-family: $font-semi-bold;
                font-size: rem(13);
                margin-bottom: 5px;
            }

            .task-status {
                position: relative;

                .status-error {
                    background: $error;
                }

                .status {
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    margin-right: 5px;
                    transform: translateY(3px);

                    &.success {
                        background: $success;
                    }

                    &.failed {
                        background: $error;
                    }

                    &.none {
                        background: $clear-grey;
                    }

                    &.processing {
                        background: $processing;
                    }

                }
            }
        }
    }
}
