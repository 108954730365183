@import './variables';

.mobile-calendar {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    background-color: white;
    border-radius: 20px !important;
    align-items: center;
    gap: 10px;
    padding: 15px 15px 30px !important;
    > h2 {
        display: none;
    }

    > div {
        width: 100% !important;
        height: 100% !important;
        .close {
            display: flex;
            justify-content: end;
        }

        .navigation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h2 {
                color: $clear-blue-grey;
            }
            .arrow-reversed {
                &.disabled {
                    fill: $clear-blue-grey !important;
                    opacity: 0.3;
                    pointer-events: none;
                }

                transform: rotate(180deg);
            }
        }

        .months-data {
            h3 {
                color: $clear-blue-grey;
            }
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 30px 30px;
            background-color: $grey-white;
            border-radius: 30px;

            span {
                color: $clear-blue-grey;
            }
        }
    }
}
